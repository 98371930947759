import { createRouter, createWebHashHistory } from 'vue-router'

// 定义一个白名单数组，动态生成路由
const webSiteRouter = []
export const webSitLists = [
  'home',
  'productOne',
  'productTwo',
  'applicationPhone',
  'applicationMini',
  'companyNews',
  'contactUs',
  'privacyPolicy',
  'license'
]

webSitLists.forEach((item) => {
  webSiteRouter.push({
    path: '/' + item,
    name: item,
    component: () => import('../views/pages/' + item)
  })
})

// 创建路由对象

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName:"login" */ '../views/login')
  },
  {
    path: '/',
    name: '/',
    component: () => import('../layout'),
    redirect: '/homepage', // 刚加载后首要展示的页面路径
    // redirect: to => {
    //   // 方法接收目标路由作为参数
    //   // return 重定向的字符串路径/路径对象
    //   // var path = sessionStorage.getItem('path')
    //   // console.log('重定向页面---' + path)

    //   // const store = useStore()
    //   // const menus = store.userInfo?.menus || []
    //   // return { name: menus[0]?.children[0]?.name || '' }
    //   return '/homepage'
    // },

    children: [
      {
        // 首页
        path: 'homepage',
        name: 'homepage',
        component: () =>
          import(/* webpackChunkName:"users" */ '@/views/homepage/index.vue')
      },
      {
        // 主页
        path: 'homepage1',
        name: 'homepage1',
        component: () =>
          import(/* webpackChunkName:"users" */ '@/views/homepage1/index.vue')
      },
      {
        path: 'chatGPT',
        name: 'chatGPT',
        component: () =>
          import(/* webpackChunkName:"users" */ '@/views/chatGPT/index.vue')
      },
      {
        // 空
        path: 'users',
        name: 'users',
        component: () =>
          import(/* webpackChunkName:"users" */ '@/views/users/index.vue')
      },
      {
        // 空
        path: 'categories',
        name: 'categories',
        component: () =>
          import(
            /* webpackChunkName:"categories" */ '@/views/categories/index.vue'
          )
      },
      {
        // 商品列表
        path: 'goods',
        name: 'goods',
        component: () =>
          import(/* webpackChunkName:"goods" */ '@/views/goods/index.vue')
      },
      {
        // 售后
        path: 'afterSales',
        name: 'afterSales',
        component: () =>
          import(/* webpackChunkName:"goods" */ '@/views/afterSales/index.vue')
      },
      {
        path: 'orders',
        name: 'orders',
        component: () =>
          import(/* webpackChunkName:"orders" */ '@/views/orders/index.vue')
      },
      {
        path: 'serviceOrders',
        name: 'serviceOrders',
        component: () =>
          import(
            /* webpackChunkName:"orders" */ '@/views/orders-service/index.vue'
          )
      },

      {
        // 用户管理
        path: 'users',
        name: 'users',
        component: () =>
          import(/* webpackChunkName:"device" */ '@/views/device/index.vue')
      },
      {
        // 空
        path: 'params',
        name: 'params',
        component: () =>
          import(/* webpackChunkName:"params" */ '@/views/params/index.vue')
      },
      {
        // 空
        path: 'reports',
        name: 'reports',
        component: () =>
          import(/* webpackChunkName:"reports" */ '@/views/reports/index.vue')
      },
      {
        // 空
        path: 'rights',
        name: 'rights',
        component: () =>
          import(/* webpackChunkName:"rights" */ '@/views/rights/index.vue')
      },
      {
        // 空
        path: 'userRoles',
        name: 'userRoles',
        component: () =>
          import(/* webpackChunkName:"userRoles" */ '@/views/roles/index.vue')
      },
      {
        // 员工管理
        path: 'workers',
        name: 'workers',
        component: () =>
          import(
            /* webpackChunkName:"roles" */ '@/views/master-manage/index.vue'
          )
      },
      {
        // 服务订单列表
        path: 'orderlistServe',
        name: 'orderlistServe',
        component: () =>
          import(
            /* webpackChunkName:"roles" */ '@/views/orderlist-serve/index.vue'
          )
      },
      {
        // 菜单管理
        path: 'menuManagement',
        name: 'menuManagement',
        component: () =>
          import(
            /* webpackChunkName:"roles" */ '@/views/menuManagement/index.vue'
          )
      },
      {
        // 权限管理-角色组管理
        path: 'role',
        name: 'role',
        component: () =>
          import(
            /* webpackChunkName:"roles" 角色组管理 */ '@/views/jurisdiction/role/role.vue'
          ),
        children: [
          {
            path: 'role',
            name: 'role',
            component: () =>
              import(
                /* webpackChunkName:"roles" 角色组管理 */ '@/views/jurisdiction/role/role.vue'
              )
          }
          // {
          //   // 权限管理
          //   path: 'Administrator',
          //   name: 'Administrator',
          //   component: () =>
          //     import(
          //       /* webpackChunkName:"roles" 管理员 */ '@/views/jurisdiction/Administrator/Administrator.vue'
          //     )
          // }
        ]
      },
      {
        // 权限管理-管理员管理
        path: 'administratorList',
        name: 'administratorList',
        component: () =>
          import(
            /* webpackChunkName:"roles" 管理员 */ '@/views/jurisdiction/Administrator/Administrator.vue'
          )
      },
      {
        // 权限管理-菜单规则
        path: 'menuRules',
        name: 'menuRules',
        component: () =>
          import(
            /* webpackChunkName:"roles" 菜单规则管理 */ '@/views/jurisdiction/MenuRule/MenuRule.vue'
          )
      },
      {
        // 权限管理-管理员日志
        path: 'operationLogs',
        name: 'operationLogs',
        component: () =>
          import(
            /* webpackChunkName:"roles" 管理员日志 */ '@/views/jurisdiction/AdministratorLog/AdministratorLog.vue'
          )
      },
      {
        // 业绩管理
        path: 'performance',
        name: 'performance',
        component: () => import(/* webpackChunkName:"login" */ '@/views/performance/index.vue')
      },
      {
        // 发票管理 invoice
        path: 'fpList',
        name: 'fpList',
        component: () => import(/* webpackChunkName:"login" */ '@/views/invoice/index.vue')
      },
      {
        // 记账中心-服务站记账
        path: 'accountServe',
        name: 'accountServe',
        component: () => import(/* webpackChunkName:"login" */ '@/views/account/accountServe/index.vue')
      },
      {
        // 运营中心-点击量统计
        // operation - clickRate
        path: 'clickRates',
        name: 'clickRates',
        component: () => import(/* webpackChunkName:"login" */ '@/views/operation/clickRate/index.vue')
      },
      {
        // 营销活动
        // marketing
        path: 'marketing',
        name: 'marketing',
        component: () => import(/* webpackChunkName:"login" */ '@/views/marketing/index.vue')
      }
    ]
  }

  // {
  //   path: '/role',
  //   name: 'role',
  //   component: () => import(/* webpackChunkName:"role" */ '../views/performance'),
  //   redirect: '/orders',
  //   children: [
  //     {
  //       // 权限管理
  //       path: 'Administrator',
  //       name: 'Administrator',
  //       component: () =>
  //         import(
  //           /* webpackChunkName:"roles" 管理员 */ '@/views/jurisdiction/Administrator/Administrator.vue'
  //         )
  //     }
  //   ]
  // }
]
routes.push(...webSiteRouter)

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
