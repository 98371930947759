import { login as loginApi, loginGly as loginGlyApi, loginFwz as loginFwzApi, loginFwzCode as loginFwzCodeApi } from '@/api/login'
// , loginFwzCode as loginCodeApi
import { Message } from '@arco-design/web-vue'
import router from '@/router'
import { setTokenTime } from '@/utils/auth'

// 引入jsonwebtoken库
import jsonwebtoken from 'jsonwebtoken'
// import { escape } from 'core-js/fn/regexp'
export default {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem('token') || '',
    menuList: localStorage.getItem('menuList')
      ? JSON.parse(localStorage.getItem('menuList'))
      : [],
    siderType: true,
    lang: localStorage.getItem('lang') || 'zh'
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    setMenu(state, menuList) {
      state.menuList = menuList
      localStorage.setItem('menuList', JSON.stringify(menuList))
    },
    setUserInfo(state, userInfo) {
      state.userInfo = JSON.stringify(userInfo)
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    changeSiderType(state) {
      state.siderType = !state.siderType
    },
    changLang(state, lang) {
      state.lang = lang
    }
  },
  actions: {
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginApi(userInfo)
          .then((res) => {
            // console.log(res.menus)
            if (res.accesstoken) {
              commit('setToken', res.accesstoken)
              commit('setMenu', res.menus ? res.menus : []) // 将登录接口返回的菜单列表显示到页面上
              // const menuList = [
              //   {
              //     id: 99,
              //     authName: '首页',
              //     path: 'homepage'
              //   },
              //   {
              //     id: 100,
              //     authName: '业绩管理',
              //     path: null,
              //     children: [
              //       {
              //         id: 1001,
              //         authName: '业绩统计',
              //         path: 'performance',
              //         children: [
              //         ]
              //       }
              //     ]
              //   },
              //   {
              //     id: 102,
              //     authName: '记账中心',
              //     path: null,
              //     children: [
              //       {
              //         id: 1011,
              //         authName: '服务站记账',
              //         path: 'accountServe',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 103,
              //     authName: '业务管理',
              //     path: null,
              //     children: [
              //       {
              //         id: 1031,
              //         authName: '服务订单管理',
              //         path: 'orderlistServe',
              //         children: []
              //       },
              //       {
              //         id: 1031,
              //         authName: '售后中心',
              //         path: 'afterSales',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 104,
              //     authName: '发票中心',
              //     path: null,
              //     children: [
              //       {
              //         id: 1041,
              //         authName: '发票管理',
              //         path: 'invoice',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 105,
              //     authName: '商品管理',
              //     path: null,
              //     children: [
              //       {
              //         id: 1051,
              //         authName: '商品列表',
              //         path: 'goods',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 106,
              //     authName: '用户管理',
              //     path: null,
              //     children: [
              //       {
              //         id: 1061,
              //         authName: '用户列表',
              //         path: 'users',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 107,
              //     authName: '员工管理',
              //     path: null,
              //     children: [
              //       {
              //         id: 1071,
              //         authName: '员工列表',
              //         path: 'masterManagement',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 108,
              //     authName: '运营中心',
              //     path: null,
              //     children: [
              //       {
              //         id: 1081,
              //         authName: '点击量统计',
              //         path: 'clickRate',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 109,
              //     authName: '营销活动',
              //     path: null,
              //     children: [
              //       {
              //         id: 1091,
              //         authName: '营销活动',
              //         path: 'marketing',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 1010,
              //     authName: '权限管理',
              //     path: null,
              //     children: [
              //       {
              //         id: 10101,
              //         authName: '角色组管理',
              //         path: 'role',
              //         children: []
              //       },
              //       {
              //         id: 10102,
              //         authName: '管理员管理',
              //         path: 'Administrator',
              //         children: []
              //       },
              //       {
              //         id: 10103,
              //         authName: '菜单规则管理',
              //         path: 'MenuRule',
              //         children: []
              //       },
              //       {
              //         id: 10104,
              //         authName: '管理员日志',
              //         path: 'AdministratorLog',
              //         children: []
              //       }
              //     ]
              //   },
              //   {
              //     id: 1011,
              //     authName: '内部调试',
              //     path: null,
              //     children: [
              //       {
              //         id: 10111,
              //         authName: '首页',
              //         path: 'homepage1',
              //         children: []
              //       },
              //       {
              //         id: 1091,
              //         authName: 'chatGPT',
              //         path: 'chatGPT',
              //         children: []
              //       }
              //     ]
              //   }
              // ]
              // commit('setMenu', menuList || [])
              setTokenTime()
              router.replace('/')
            } else {
              Message.error('账号密码错误！')
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 平台管理员登录
    loginGly({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginGlyApi(userInfo)
          .then((res) => {
            if (res.accesstoken) {
              commit('setToken', res.accesstoken)
              commit('setMenu', res.menus ? res.menus : []) // 将登录接口返回的菜单列表显示到页面上
              setTokenTime()
              // 解析JWT
              const userinfo = jsonwebtoken.decode(res.idtoken)
              commit('setUserInfo', userinfo)
              router.replace('/') // 跳转到新的页面
            } else {
              Message.error(res.description)
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 服务站登录 loginFwzApi
    loginFwz({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginFwzApi(userInfo)
          .then((res) => {
            console.log(res)
            // if (res.status === 'success') {
            //   Message.error(res.description)

            // } else {
            //   Message.error(res.description)

            // }

            if (res.status === 'success') {
              // commit('setToken', res.accesstoken)
              // commit('setMenu', res.menus ? res.menus : []) // 将登录接口返回的菜单列表显示到页面上
              // setTokenTime()
              // // 解析JWT
              // const userinfo = jsonwebtoken.decode(res.idtoken)
              // console.log(userinfo)
              // const data = res
              // data.idtoken = userinfo
              // commit('setUserInfo', userinfo)
              // router.replace('/') // 跳转到新的页面

              // res.data 用户的手机号
              resolve(res.data)
            } else {
              Message.error(res.description)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 输入验证码
    // codeInfo ：输入验证码所虚的手机号和验证码
    // userInfo：服务站登录后返回的内容在验证码输入正确之后存储到本地

    fwzloginCode({ commit }, codeInfo) {
      return new Promise((resolve, reject) => {
        var codeData = {
          code: codeInfo.code,
          phone: codeInfo.phone,
          account: codeInfo.accountValue
        }
        loginFwzCodeApi(codeData)
          .then((res) => {
            if (res.status === 'success') {
              commit('setToken', res.accesstoken)
              commit('setMenu', res.menus ? res.menus : []) // 将登录接口返回的菜单列表显示到页面上
              setTokenTime()
              // 解析JWT
              const userinfo = jsonwebtoken.decode(res.idtoken)
              commit('setUserInfo', userinfo)
              router.replace('/') // 跳转到新的页面
            } else {
              Message.error(res.description)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 退出
    logout({ commit }) {
      commit('setToken', '')
      sessionStorage.setItem('path', '/orders')
      localStorage.clear()
      window.location.reload()
      // router.push('/login')
    }
  }
}
